import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from './hooks/LanguageContext';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import ModalInstruction from './ModalInstruction';

const MultipleChoiceQuizNoImage = ({ question, selectedOptionsMcq, setSelectedOptionsMcq, answer, isMarked }) => {
  
  const { language } = useContext(LanguageContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOptionSelect = (optionIndex, question_type) => {
    // Check the number of selected options
    const checkedCount = selectedOptionsMcq.length;

    // If the maximum allowed options are not selected, allow the checkbox to be checked
    if (checkedCount < 6 || selectedOptionsMcq.includes(optionIndex)) {
      setSelectedOptionsMcq((prevOptions) => {
        if (prevOptions.includes(optionIndex)) {
          return prevOptions.filter((index) => index !== optionIndex);
        } else {
          return [...prevOptions, optionIndex];
        }
      });
    }
  }
  
  return (
    <>
      <div className='justify-content-end d-flex' >
        {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
        <div onClick={handleShow}>
          <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sectionsBox">
            <p className="mb-0 text-center">{question.title[language]}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          {question.question_title[language].length > 0 &&
            <div className="contentbox mb-2 mt-3">
              <div className="content_safty ">
                {parse(question.question_title[language])}
              </div>
              <div className="content_safty ">
                {parse(question.description[language])}
              </div>
            </div>
          }
        </div>

        <div className="col-12">
          <div className="textBox my-3">
            <p className="d-flex gap-2">
              {question.question_text}
            </p>

            <form action="" className="boder-bottom-set">
              {question.question_option.map((option, index) => (
                <div className="form-check " key={index}>
                  <div className="d-flex  align-items-top">

                    <input
                      type="checkbox"
                      name={`option_${option.id}`}
                      value={option.title[language]}
                      checked={
                        isMarked === 0 ?
                          selectedOptionsMcq.includes(Number(option.id))
                          :
                          answer.course_module_item[0].option_marked.includes(Number(option.id))

                      }
                      onChange={() => handleOptionSelect(option.id, question.question_type)}
                      className="form-check-input me-2"
                    // disabled={selectedOptionsMcq.length === 3}
                    />
                    <label className="form-check-label" htmlFor="nda" style={{ lineHeight: "24px", marginTop: 4, }}>
                      {option.title[language]}
                      {(isMarked === 1 && (answer.course_module_item[0].option_marked.includes(Number(option.id)) && answer.course_module_item[0].correct_option.includes(Number(option.id)))) ? <FontAwesomeIcon icon={faCircleCheck} size="lg" color="#007B19" className='ms-2 mt-1' style={{ paddingTop: 2 }} /> : ""}
                      {(isMarked === 1 && (answer.course_module_item[0].option_marked.includes(Number(option.id)) && !answer.course_module_item[0].correct_option.includes(Number(option.id)))) ? <FontAwesomeIcon icon={faCircleXmark} size="lg" color="#F62020" className='ms-2 mt-1' style={{ paddingTop: 2 }} /> : ""}
                    </label>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>

      </div>

      <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
    </>
  );
};

export default MultipleChoiceQuizNoImage;
