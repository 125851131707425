import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import LanguageSet from "../components/language/LanguageSet";
import LanguageContext from "../components/hooks/LanguageContext";
import { faEnvelope, faPen, faPhone, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";

const url = `${Constants.BASE_URL}/student-update-phone`;
const urlEmail = `${Constants.BASE_URL}/student-update-email`;
const StudentProfile = () => {
  const { user, logout: authLogout} = useAuth(); // Use useAuth
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [isMobileEditing, setIsMobileEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);

  const [check, setCheck] = useState(false)
  // "http://restapi.ylac.st/student-detail?access_token=1695718248_Tg_Ru8QR1oNtRzVdldrA0cbcUfDhVaKQ"
  const handleMobileEdit = () => {
    setIsMobileEditing(true);
  };

  const handleEmailEdit = () => {
    setIsEmailEditing(true);
  };

  const handleMobileSave = async () => {
    if (mobile && mobile.length >= 10) {

      if (mobile && mobile.charAt(0) !== '0') {
        try {
          const formData = new FormData();
          formData.append("phone_number", mobile);
          formData.append("access_token", user?.token);

          const response = await axios.post(url, formData,
            {
              headers: {
                'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                "Access-Control-Allow-Origin": "*"
              }
            }
          );

          
          handleApiResponse(response.data);

        } catch (error) {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      } else {
        // Display an error message because the first digit is 0.
        toast.error('Mobile number should not start with 0.');
      }
    }else {
      // Display an error message for not meeting the minimum digit requirement
      toast.error('Mobile number should have a minimum of 10 digits.');
    }

    // Perform save operation for mobile number (e.g., send to server)
  };

  const handleApiResponse = (data) => {
    if (data.message === "Phone Number Updated Successful") {
      toast.success('Thank You, Phone Number Updated Successful');
      setMobile(data.result.student_phone)
      setIsMobileEditing(false)
    } else {
      toast.warning(data.message);
    }
  };

  const handleEmailSave = async () => {
    // Check if email is valid before saving
    if (validateEmail(email)) {

      try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("access_token", user?.token);

        const response = await axios.post(urlEmail, formData,
          {
            headers: {
              'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
              "Access-Control-Allow-Origin": "*"
            }
          }
        );
        handleApiResponseEmail(response.data);

      } catch (error) {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.error('Invalid email address');
    }
  };

  // Function to validate an email using a regular expression
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleApiResponseEmail = (data) => {
    if (data.message === "Email Updated Successful") {
      toast.success('Thank You, Email Updated Successful');
      setEmail(data.result.student_email)
      setIsEmailEditing(false);
    } else {
      toast.warning(data.message);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let newUrl = `${Constants.BASE_URL}/student-detail?access_token=${user?.token}`
        const response = await axios.get(newUrl);

        if (response.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else {
          setMobile(response?.data?.student_summary?.student_phone)
          setEmail(response?.data?.student_summary?.student_email)
          setLoading(false);
        }


      }
      catch (error) {
        console.error(error);
        setLoading(false)
      }
    }
    fetchData();
  }, [])
  return (
    <div id="body_bg">
      <Header
        page_title={language === "english" ? "Profile" : "प्रोफ़ाइल"}

      />
      <LanguageSet />
      <div className="container-fluid px-0">

        <div className="row justify-content-center px-2 mt-4">
          <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12 mt-3">
            <div className="student_profileBox ">
              <div className="logo_profile text-center pb-4">
                <img src="../assets/images/YLAC logo-01.png" alt="logo" width="215" />
              </div>
              <div className=" StudentNames  ">
                <h2>{user?.student_name[language]}</h2>
                <p className="mb-0">
                  {language === "english" ? "Student Id: " : "स्टूडेंट आईडी: "}
                  <span>{user?.student_id}</span>
                </p>
              </div>
              <div className=" StudentNames  ">
                <p className="mb-0">
                  {language === "english" ? "School Name: " : "स्कूल का नाम: "}
                  <span>{user?.school_name}</span>
                </p>
                <p className="mb-0">
                  {language === "english" ? "City Name: " : "शहर का नाम: "}
                  <span>{user?.city_name}</span>
                </p>
              </div>

              <div className=" StudentNames  ">
                <p className="mt-3" >{language === "english" ? "If you want the team to send updates about the program to you, please share your mobile or email id here" : "यदि आप चाहते हैं कि टीम आपको कार्यक्रम के बारे में अपडेट भेजे, तो कृपया अपना मोबाइल या ईमेल आईडी यहां साझा करें"}</p>


                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="myDetails"
                    name="myDetails"
                    checked={check} // Bind to the state variable
                    onChange={(e) => setCheck(e.target.checked)} // Update the state on change
                  />
                  <label className="form-check-label ms-2 fontFam" htmlFor="myDetails" style={{ color: "#707070" }}>{language === "english" ? "Yes, I would like to share my details" : "हाँ, मैं अपना विवरण साझा करना चाहूँगा"}</label>
                </div>

                {isMobileEditing ? (
                  <>
                    <div className="input-group">
                      <input
                        type="tel"
                        maxLength="10"
                        className="form-control"
                        value={mobile}
                        onChange={(e) => {
                          const onlyDigits = e.target.value.replace(/\D/g, "");
                          setMobile(onlyDigits)
                        }
                        }
                      />
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                      <button type="button" className="btn btn-outline-danger" onClick={() => { setIsMobileEditing(false) }}>Cancel</button>
                      <button
                        className="btn btn-primary"
                        onClick={handleMobileSave}
                        style={{ backgroundColor: "#792110", borderColor: "#792110" }}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="contentprofile">
                        <p className="mb-0">
                          {language === "english" ? "Mobile: " : "मोबाइल नंबर: "}

                          <span>{mobile}</span>
                        </p>
                      </div>
                      {check && <div>
                        <button
                          className="btn btn-link"
                          onClick={handleMobileEdit}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="sm"
                            color="#707070"
                          />
                        </button>
                      </div>
                      }
                    </div>


                  </>
                )}


                {isEmailEditing ? (
                  <>
                    <div className="input-group mt-4">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mt-3 d-flex justify-content-between">
                      <button type="button" className="btn btn-outline-danger" onClick={() => { setIsEmailEditing(false) }}>Cancel</button>
                      <button
                        className="btn btn-primary"
                        onClick={handleEmailSave}
                        style={{ backgroundColor: "#792110", borderColor: "#792110" }}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="contentprofile">
                        <p className="mb-0">
                          {language === "english" ? "Email Id: " : "ईमेल आईडी: "}
                          <span>{email}</span>
                        </p>
                      </div>
                      {check && <div>
                        <button
                          className="btn btn-link"
                          onClick={handleEmailEdit}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="sm"
                            color="#707070"
                          />
                        </button>
                      </div>
                      }
                    </div>


                  </>
                )}
              </div>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default StudentProfile;
