import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext"
import { useContext, useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

const API_CERTIFICATE = `${Constants.BASE_URL}/appcertificate`;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const Certificate = () => {
    const { user, logout: authLogout, setMyPoints } = useAuth(); // Use useAuth
    const { language } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [module, setModule] = useState(null)
    const [showInst, setShowInst] = useState(true);

    const handleClose = () => setShowInst(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(`${API_CERTIFICATE}?access_token=${user?.token}`);
                
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setModule(response.data)
                }
                setLoading(false)
            } catch (error) {
                // console.error(error);
                setLoading(false)
            }
        }
        fetchData();
    }, [user?.token]);

    useEffect(() => {
        setLoading(true)
        let newUrl = `${API_STUDENT_PROGRESS}?access_token=${user?.token}`
        axios.post(newUrl).then((response) => {
            if (response.data.message === "Invalid Access Token") {
                authLogout()
                navigate("/");
            } else {
                
                setMyPoints(response.data.student_summary.points)
                setLoading(false);
            }
        }).catch((err) => {
            console.error(err)
            setLoading(false)
        });
    }, [])

    return (
        <>
            <div id="body_bg">
                <Header page_title={language === "english" ? "Certificate" : "प्रमाणपत्र"} />
                <LanguageSet />
                <div className="container-fluid px-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-12">
                            <section className="my-3">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            {module?.certificate_summary?.certificate_status === true &&
                                                <>
                                                    <div class="sectionsBox"><p class="mb-0 text-center">Certificate</p>

                                                    </div>
                                                    <div className="annoucemnet_bx mb-2 mt-5">
                                                        <div className="row">
                                                            <div className="col-8 d-flex justify-content-between align-items-center">
                                                                <a className="leftannou ms-5" style={{ textDecorationColor: "#2f4672" }} href={`${Constants.BASE_URL}${module.certificate_summary.pdf}`} target='_blank' rel='noopener noreferrer'>
                                                                    <p className="mb-0" style={{ color: "#2f4672" }}>{"Download"}</p>
                                                                </a>
                                                            </div>
                                                            <div className="col-4  align-items-end d-flex justify-content-end ">
                                                                <div className="rightannou">
                                                                    <a href={`${Constants.BASE_URL}${module.certificate_summary.pdf}`} target='_blank' download rel="noreferrer" className="me-5">
                                                                        <FontAwesomeIcon icon={faDownload} size="sm" color="#002859" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {module?.certificate_summary?.certificate_status === false && <div className="" style={{ minHeight: "150px" }}>

                                                <p className="mb-0 text-center">
                                                    {module?.certificate_summary?.common_message[language]}
                                                </p>
                                                <Modal show={showInst} onHide={handleClose}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Body>
                                                        {/* {language === "english" && "Please ask your class teacher to approve your certificate on the platform so you can download it."}
                                                        {language === "hindi" && "कृपया अपने शिक्षक से आग्रह करें की वे आपके प्रमाणपत्र को प्लेटफार्म पर मंजूरी दें, ताकि आप उसे डाउनलोड कर सकें।"} */}
                                                        {module?.certificate_summary?.common_message[language]}
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Certificate