import React, { useContext, useState } from 'react';
import LanguageContext from './hooks/LanguageContext';
import parse from 'html-react-parser';
import ModalInstruction from './ModalInstruction';
const SingleChoiceQuizImage = ({ question, selectedOptions, setSelectedOptions, answer, isMarked }) => {
 

  const { language } = useContext(LanguageContext);
  const [isSelected, setIsSelected] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOptionChange = (id, title) => {
    
    setSelectedOptions({
      id: id,
      title: title
    });
  };
  
  return (
    <>
      <div className='justify-content-end d-flex' >
        {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
        <div onClick={handleShow}>
          <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sectionsBox">
            <p className="mb-0 text-center">{question.title[language]}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          {question.description[language].length > 0 && <div className="contentbox mb-2 mt-3">
            <div className="content_safty ">
              {parse(question.description[language])}
            </div>
          </div>
          }
        </div>
        <div className="col-12">

          <div className="textBox pb-1 pt-2 position-relative">
            <div className="row justify-content-center">
              <div className="col-0 col-xxl-2 col-xl-2 col-lg-2  col-sm-1  col-md-2"></div>
              <div className="col-12 col-xxl-8 col-xl-8 col-lg-8 col-sm-10   col-md-8" >
                <div className="shareEmail">
                  <div className='row d-flex align-items-center' style={{ height: "100%" }}>
                    <div className='col-0 col-lg-1'></div>
                    <div className='col-12 col-lg-6'>
                      <div className="emailText" style={{ textAlign: "center" }}>
                        {
                          language === "english" && <>
                            <p style={{ marginBottom: 2 }}>Can you share your</p>
                            <p style={{ borderBottom: "3px solid #314873", marginBottom: 2, display: "inline-block" }}>
                              {question.question_title[language]}
                            </p>
                          </>
                        }
                        {
                          language === "hindi" && <>
                            <p style={{ marginBottom: 2 }}>{`क्या आप`}</p>
                            <p style={{ borderBottom: "3px solid #314873", marginBottom: 2, display: "inline-block" }}>
                              {question.question_title[language]}
                            </p>
                          </>
                        }

                        {/* <p style={{ marginBottom: 2 }}>{language === "english" ? "Can you share your" : "क्या आप अपना शेयर कर सकते हैं"}</p>
                        <p style={{ borderBottom: "3px solid #314873", marginBottom: 2, display: "inline-block" }}>
                          {question.question_title[language]}
                        </p> */}
                      </div>
                      <div className="emailText" style={{ textAlign: "center" }}>
                        {question.question_category[language] === "अजनबी" && <p style={{ display: "inline-block" }}>एक अजनबी के साथ साझा करेंगे?</p>}
                        {question.question_category[language] === "stranger" && <p style={{ display: "inline-block" }}>With a stranger?</p>}
                        {question.question_category[language] === "ऑनलाइन" && <p style={{ display: "inline-block" }}>ऑनलाइन साझा करेंगे?</p>}
                        {question.question_category[language] === "online" && <p style={{ display: "inline-block" }}>Online?</p>}
                        {/* {language=== "hindi" && <p>{question.question_category[language]}</p>} */}
                      </div>
                    </div>
                    <div className='col-0 col-lg-5'></div>
                  </div>
                </div>
              </div>
              <div className='col-0 col-xxl-2 col-xl-2 col-lg-2  col-sm-1  col-md-2'></div>
            </div>
            <div className='row '>
              <div className='col-xl-12'>
                <form className="single-type pt-4">
                  <div className="row justify-content-center text-center">
                    {/* {question.question_option[language].map((option, index) => (
                  <div className="col-6 mb-4" key={index}>
                    <div className="questionButton position-relative">
                      <div onClick={() => { handleOptionChange(option.id, option.title) }} className={`btn-school ms-3 
                          ${(isMarked === 0 && (option.id === selectedOptions.id)) ? 'selectedButton' : ''} 
                          ${(isMarked === 1 && (option.id === answer.course_module_item[0].correct_option.id)) ? 'correct-answer' : ''}
                          ${(isMarked === 1 && ((option.id === Number(answer.course_module_item[0].option_marked.id)) && (Number(answer.course_module_item[0].option_marked.id) !== answer.course_module_item[0].correct_option.id))) ? 'wrong-answer' : ''}
                          ${(isMarked === 1 && ((option.id === answer.course_module_item[0].correct_option.id) && (option.id === Number(answer.course_module_item[0].option_marked.id)))) ? 'correct-answer-correct-option' : ''}
                          
                      `}>{option.title}
                        <div className="obj1">
                          <img src={require("../assets/images/twemoji_cloud-with-rain.png")} alt="" />
                        </div>  <div className="obj2">
                          <img src={require("../assets/images/star.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}

                    {question.question_option[language].map((option, index) => (
                      <div className="col-6 col-lg-6 mb-4" key={index}>
                        <div className="questionButton position-relative">
                          <div style={{ fontSize: 14 }} onClick={() => { handleOptionChange(option.id, option.title) }} className={`btn-school ms-3 
                          ${(isMarked === 0 && (option.id === selectedOptions.id)) ? 'selectedButton' : ''}
                          ${(isMarked === 1 && (option.id === Number(answer.course_module_item[0].option_marked.id))) ? 'markedChoice' : ''}
                      `}>{option.title}
                            {/* {(isMarked === 1 && (Number(answer.course_module_item[0].correct_option.id) === Number(answer.course_module_item[0].option_marked.id))  && (option.id === Number(answer.course_module_item[0].option_marked.id))) ? <FontAwesomeIcon icon={faCircleCheck} size="lg" color="#007B19" className='ms-2' /> : "" }
                      {(isMarked === 1 && (Number(answer.course_module_item[0].correct_option.id) !== Number(answer.course_module_item[0].option_marked.id))  && (option.id === Number(answer.course_module_item[0].option_marked.id))) ?  <FontAwesomeIcon icon={faCircleXmark} size="lg" color="#F62020" className='ms-2'/> : "" } */}
                            <div className="obj1">
                              <img src={require("../assets/images/twemoji_cloud-with-rain.png")} alt="" />
                            </div>  <div className="obj2">
                              <img src={require("../assets/images/star.png")} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
      <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
    </>
  );
};

export default SingleChoiceQuizImage;
