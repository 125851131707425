import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBars } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useNavigate } from 'react-router-dom';
import menuJson from "../api/menu.json"
import { useContext, useEffect, useState } from 'react';
import LanguageContext from './hooks/LanguageContext';
import { useAuth } from '../providers/AuthContext';

const Header = ({ page_title,  pageTitle }) => {
    const { user, logout: authLogout, myPoints} = useAuth(); // Use useAuth
    const navigate = useNavigate()
    const [course, setCourse] = useState(false)
    const { language, } = useContext(LanguageContext);
    const [sideBar, setSideBar] = useState(false)
    const OpenCourse = () => { setCourse(!course)}

    const [showPoints, setShowPoints] = useState(0)
    const [showLogo, setShowLogo] = useState(true)
    const [flipLogo, setFlipLogo] = useState(false)

    useEffect(() => {
    
        if (myPoints > showPoints) {
            // Flip logo for 2 seconds
            setFlipLogo(true);
            setTimeout(() => {
                setFlipLogo(false);
                setShowLogo(false);

                // Show points for 2 seconds
                setTimeout(() => {
                    setShowPoints(myPoints);
                }, 2000);

                // Flip logo again for 2 seconds after showing the points
                setTimeout(() => {
                    setShowLogo(true);
                }, 4000); // Wait for 2 seconds after showing the points
            }, 2000);
            setShowPoints(myPoints);
        }
    }, [myPoints]);




    return (
        <nav className="navbar fixed-top " >
            <div className="mainHeader">
                <div className="container-fluid">
                    <div className="container-fluid px-0">
                        <div className="header_brands d-flex justify-content-between align-items-center" >
                            <div className="left_nav d-flex gap-3 align-items-center">
                                {pageTitle === "AskAge" ? "" : <div className="" type="button" onClick={() => { setSideBar(!sideBar) }}  >
                                    <FontAwesomeIcon icon={faBars} size="xl" color="#fff" />
                                </div>
                                }
                                <div className="studentNmae">
                                    {/* <p className="mb-0">{menuJson.page_title[language]}</p> */}
                                    <p className="mb-0">{page_title}</p>
                                </div>
                            </div>
                            <div className="right_logp">
                                <NavLink to="/">
                                    {showLogo ?
                                        <img src={require('../assets/images/logobg.png')} alt="" width="50"

                                            className={flipLogo ? "flip-logo" : ""}
                                        />
                                        :
                                        <div className='counter_box2 '>
                                            <img src={require('../assets/images/logoCoin.png')} alt="" width="50" />
                                            <div className='pointCentered'>{myPoints}</div>
                                        </div>
                                    }
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={sideBar ? "offcanvas offcanvas-start show" : "offcanvas offcanvas-start"} tabIndex="-1" style={{ transition: "all .3s", }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="logoNav pt-3">
                            <img src={require('../assets/images/YLAC logo-01.png')} alt="" width="150" />
                        </div>
                        <div className="offcanvas-header d-inline">
                            <button type="button" className="btn-close" onClick={() => { setSideBar(false) }} aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="offcanvas-body">
                        <div className="navigation-links side-bar">
                            <div className="student_name position-relative">
                                <div className="icon-d d-flex align-items-center gap-3">
                                    <div className="user-ic">
                                        <img src={require('../assets/images/icons/gg_profile.png')} alt="" />
                                    </div>
                                    <div className="studen-login" onClick={() => { navigate("/profile") }} style={{cursor:"pointer"}}>
                                        <h2>{language === "english" ? "Hello" : "हलो"}, {user?.student_name[language]}</h2>
                                        <h4>{user?.student_id}</h4>
                                    </div>
                                </div>

                            </div>
                            <ul className="menu mt-3">
                                {menuJson?.menu?.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {
                                                (index === 5 && user?.is_ambassador === 0) ? ""
                                                    :

                                                    index !== 2 ?
                                                        <>
                                                            <NavLink className="d-flex gap-3 ps-2 align-items-center" to={item.to} onClick={() => { setSideBar(false) }}
                                                            ><img src={`${item.icon}`} alt="" />{item.title[language]}</NavLink>
                                                        </> :

                                                        <div className="item">
                                                            <div className="sub-btn d-flex gap-3 py-2 align-items-center justify-content-between" onClick={OpenCourse}>
                                                                <div className='gap-3 align-items-center d-flex ms-2'>
                                                                    <img src={item.icon} alt="" />

                                                                    {item.title[language]}
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    {course ? <FontAwesomeIcon icon={faAngleDown} className="ml-auto" /> : <FontAwesomeIcon icon={faAngleUp} />}
                                                                </div>
                                                            </div>
                                                            <div className="sub-menu" style={{ display: course ? "block" : "none" }}>
                                                                {item.courses.map((value, key) => {
                                                                    return (
                                                                        <NavLink key={key} to={value.to} state={{ url: value.url, page_title: value.title }} className="sub-item">{value.title[language]}</NavLink>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                            }
                                        </li>
                                    )
                                })}
                                <li>
                                    <div className="d-flex gap-3 align-items-center logout_font ms-2" style={{cursor:"pointer", paddingTop:10, paddingBottom: 10, borderRadius:5 }} onClick={() => {
                                        authLogout()
                                         navigate("/");
    
                                    }}
                                    ><img src={require("../assets/images/icons/Left icon.png")} alt="" style={{ marginLeft: "11px" }} />{language === "english" ? "Log Out" : "लॉग आउट"}</div>
                                </li>
                            </ul>
                            <div style={{ marginLeft: -36 }}>
                                <img src={require("../assets/images/icons/menu_left.png")}  alt="menu"/>
                            </div>
                        </div>

                    </div>
                </div>
                {sideBar ? <div className="offcanvas-backdrop fade show" onClick={() => { setSideBar(!sideBar) }} ></div> : ""}
            </div>
        </nav>
    )
}

export default Header