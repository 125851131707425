import logo from '../assets/images/YLAC Digital.png';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Constants from '../common/Constants';
import { getBrowser, getDeviceType, getOperatingSystem, getUserIPAddress } from '../utils/GetDeciceInfo';
import { useAuth } from '../providers/AuthContext'; // Import useAuth

const LoginPage = ({ handleLogin }) => {
    const {  login: authLogin } = useAuth(); // Use useAuth
    const [loading, setLoading] = useState(false)
    const [username, usernameupdate] = useState('');
    const [password, passwordupdate] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false); // State for showing/hiding the password

    // useEffect(() => {
    //     sessionStorage.clear();
    // }, []);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const ProceedLoginusingAPI = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true)
           // Gather device information
            try {
                let deviceInfo;
                try {
                    deviceInfo = {
                        deviceType: getDeviceType(),
                        os: getOperatingSystem(),
                        browser: getBrowser(),
                        ipAddress: await getUserIPAddress(),
                    };
                    
                } catch (error) {
                    console.error('Error getting device information:', error);
                }


                const response = await axios.post(
                    `${Constants.BASE_URL}/student-login`,
                    {
                        student_id: username,
                        password: password,
                        'device_type': deviceInfo.deviceType,
                        'os': deviceInfo.os,
                        'browser': deviceInfo.browser,
                        'ip_address': deviceInfo.ipAddress
                    },
                    {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            'Access-Control-Allow-Origin': '*'
                        },
                    }
                );
                 
                

                if (response.status === 200 && response.data.message === 'Login Successful') {
                    toast.success('Success');
                    authLogin(response.data.result); // Call the login function from useAuth
                    // handleLogin();
                } else {
                    toast.error(response.data.message || 'Login failed');
                }
            } catch (error) {
                console.error('Error during API call:', error);

                // Display a generic error message for network issues
                toast.error('An error occurred. Please try again.');

                // Uncomment the line below if you want to log the detailed error to the console
                // console.error(error);
            } finally {
                setLoading(false);
            }



        }
    }
    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
            toast.error('Please Enter Username');
        }
        if (password === '' || password === null) {
            result = false;
            toast.error('Please Enter Password');
        }
        return result;
    }


    return (
        <div id="landingPageBody">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div>
                <div className="container">
                    <div className="row justify-content-center" >
                        <div className="col-12  text-center login_logo">
                            <img src={logo} alt="" width="100%" style={{ margin: "0px" }} />
                        </div>
                    </div>
                </div>
                <div className="bg-img">
                    <img src={require('../assets/images/bgimg1.png')} alt="" width="100%" style={{ height: "100%", margin: "0px" }} />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6 col-sm-12">
                            <form onSubmit={ProceedLoginusingAPI} className="container">
                                <div className="inputFields input-group mb-3">
                                    <input type='text' value={username} onChange={e => usernameupdate(e.target.value)} className="form-control" placeholder="Student ID" />
                                </div>
                                <div className="inputFields input-group mb-3">
                                    <input type={showPassword ? 'text' : 'password'} value={password} onChange={e => passwordupdate(e.target.value)} className="form-control passwordInput" placeholder="Password" />
                                    <span
                                        className="input-group-text"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? (
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        ) : (
                                            <FontAwesomeIcon icon={faEye} />
                                        )}
                                    </span>

                                </div>
                                <div className="inputFields mb-3 d-flex align-items-center gap-3" >
                                    <div className="form-check">
                                        <input className="form-check-input me-2" type="checkbox" id="remember" defaultChecked={rememberMe} onChange={e => setRememberMe(!rememberMe)} />
                                        <label className="form-check-label remember_text" htmlFor="remember" >Remember me</label>
                                    </div>
                                </div>
                                <div className="inputFields mb-3">
                                    <button type="submit" disabled={loading} >
                                        {loading ? <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span> : ""}
                                        Sign In
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )
}

export default LoginPage