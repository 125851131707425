import React, { useContext, useState } from 'react';
import LanguageContext from './hooks/LanguageContext';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import ModalInstruction from './ModalInstruction';

const SingleSelectRadio = ({ question, selectedOptions, setSelectedOptions, answer, isMarked }) => {
  const { language } = useContext(LanguageContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOptionChange = (option, index) => {
    setSelectedOptions(option);
  };

  return (
    <>
      <div className='justify-content-end d-flex' >
        <div onClick={handleShow}>
          <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' alt="instructions"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sectionsBox">
            <p className="mb-0 text-center">{question.title[language]}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          {question.question_title[language].length > 0 &&
            <div className="contentbox mb-2 mt-3">
              <div className="content_safty ">
                {parse(question.question_title[language])}
              </div>
              <div className="content_safty ">
                {parse(question.description[language])}
              </div>
            </div>
          }
        </div>
        <div className="col-12">

          <div className="textBox pb-1 pt-2 position-relative">
            <div className="shareEmail3 ">
              {/* {question?.file_path[language] ? <img src={question.file_path[language]} alt="" width="100%" /> : "" } */}

              <form className="single-type pt-4">
                <div className="row justify-content-center text-center singleRadiotext">

                  {question.question_option.map((option, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        name="option"
                        value={option}
                        checked={isMarked === 1 ? answer.course_module_item[0].option_marked.id == option.id : selectedOptions.id == option.id}
                        onChange={() => handleOptionChange(option, index)}
                      />
                      <span >{option.title[language]} </span>
                      {(isMarked === 1 && (Number(answer.course_module_item[0].correct_option.id) === Number(answer.course_module_item[0].option_marked.id)) && (option.id === Number(answer.course_module_item[0].option_marked.id))) ? <FontAwesomeIcon icon={faCircleCheck} size="lg" color="#007B19" className='ms-2 mt-1' style={{ paddingTop: 2 }} /> : ""}
                      {(isMarked === 1 && (Number(answer.course_module_item[0].correct_option.id) !== Number(answer.course_module_item[0].option_marked.id)) && (option.id === Number(answer.course_module_item[0].option_marked.id))) ? <FontAwesomeIcon icon={faCircleXmark} size="lg" color="#F62020" className='ms-2 mt-1' style={{ paddingTop: 2 }} /> : ""}
                    </label>
                  ))}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
    </>
  );
};

export default SingleSelectRadio;
