import parse from 'html-react-parser';
import { useState } from 'react';
import ModalInstruction from './ModalInstruction';

const SingleSelectPdfImage = ({ language, question, setSelectedOptions, selectedOptions, answer, isMarked }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleOptionChange = (id, title) => {
     
        setSelectedOptions({
            id: id,
            title: title
        });
    };

    return (
        <>
            <div className='justify-content-end d-flex' >
                {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                <div onClick={handleShow}>
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' alt="instructions"/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox">
                        <p className="mb-0 text-center">{question.title[language]}</p>
                    </div>
                </div>
            </div>

            {/* <div className="row">
                <div className="col-12 my-4"> */}
            {/* <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}><iframe toolbar={0} src={question.file_path[language] + "#toolbar=0"} width="425" height="600" frameborder="0" /></div> */}
            {/* <PdfShowOnly question={question} /> */}
            {/* </div>
            </div> */}
            <div className="row">
                <div className="col-12 ">
                    {question.description[language].length > 0 && <div className="contentbox mb-2 mt-3">
                        <div className="content_safty ">
                            {parse(question.description[language])}
                        </div>
                    </div>
                    }
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-12 mb-3">

                        {question.question_option.map((item, index) => (

                            <div className="vedioSelectBox my-4" key={index}>
                                <div
                                    className={`image-radio-button`}
                                    onClick={() => { handleOptionChange(item.id, item.title[language]) }}
                                >
                                    {/* <div className={`optionsTop text-center
                                     ${(isMarked === 0 && selectedOptions.id === item.id) ? 'selectedButton' : ''}
                                     ${(isMarked === 1 && (item.id === answer.course_module_item[0].correct_option.id)) ? 'correct-answer' : ''}
                                     ${(isMarked === 1 && ((item.id === Number(answer.course_module_item[0].option_marked.id)) && (Number(answer.course_module_item[0].option_marked.id) !== answer.course_module_item[0].correct_option.id))) ? 'wrong-answer' : ''}
                                     ${(isMarked === 1 && ((item.id === answer.course_module_item[0].correct_option.id) && (item.id === Number(answer.course_module_item[0].option_marked.id)))) ? 'correct-answer-correct-option' : ''}
                                     
                                     
                                     `}> */}
                                    <div className={`optionsTop text-center
                                     ${(isMarked === 0 && selectedOptions.id === item.id) ? 'selectedButton' : ''}
                                     ${(isMarked === 1 && (item.id === Number(answer.course_module_item[0].option_marked.id))) ? 'markedChoice' : ''}
                                     
                                     
                                     `}>
                                        <p className={`mb-0`}>Option {index + 1}</p>
                                        <img src={item.file_path[language]} width={"100%"} style={{ margin: 0, backgroundColor: "#fff" }} />
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </form>

            {/* <div style={{display:"flex", justifyContent:"center", marginBottom:20}}><iframe toolbar={0} src={question.file_path[language]+"#toolbar=0"} width="425" height="600" frameborder="0" /></div> */}



            <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
        </>
    )
}

export default SingleSelectPdfImage