
import { useContext, useState, useEffect } from "react";
import aboutData from "../api/aboutus.json"
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSnip from "../components/LoadingSnip";
import ReadMoreResourcesShortLongAbout from "../components/ReadMoreResourcesShortLongAbout";
import parse from 'html-react-parser';
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";

const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const AboutUs = () => {
    const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
    const { language } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    // const [readMore, setReadMore] = useState(false)
    const navigate = useNavigate()
    

    useEffect(() => {
        let newUrl = `${Constants.BASE_URL}/appcboutdcp?access_token=${user?.token}`
        const fetchData = async () => {
            try {
                const response = await axios.post(newUrl);
               
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    
                    setData(response.data)
                    setLoading(false)
                }

                const response2 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`);
                
                if (response2.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {

                    setMyPoints(response2.data.student_summary.points)
                    setLoading(false);
                }
            }
            catch (error) {
                console.error(error);
                setLoading(false)
            }


        }
        fetchData();
    }, [])


    return (
        <>
            <div id="body_bg">
                <Header page_title={aboutData.page_title[language]}  />
                <LanguageSet />
                {loading ?
                    (<LoadingSnip />)
                    : (
                        <div className="container-fluid px-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-7 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid">
                                            {data?.aboutdcp_summary?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.type === 2 &&
                                                            <div key={index}>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="sectionsBox">
                                                                            <p className="mb-0 text-center text-capitalize" style={{ fontSize: 15, fontWeight: 900 }}>{item?.title[language]}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        {
                                                                            item?.content?.map((value, index) => {
                                                                                return (
                                                                                    <div className="contentbox mb-2 mt-3" key={index}>
                                                                                        <div className="content_safty">
                                                                                            <div className="row ">
                                                                                                {language === "english" && <ReadMoreResourcesShortLongAbout short={value?.short_description[language]} long={value.long_description[language]} filePath={value?.file_path[language]} language={language} />}
                                                                                                {language === "hindi" && <ReadMoreResourcesShortLongAbout short={value?.short_description[language]} long={value.long_description[language]} filePath={value?.file_path[language]} language={language} />}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {item.type === 1 &&
                                                            <div className="row">
                                                                <Accordion defaultActiveKey="0">
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header className="acc-btn accordian-btn" style={{ borderRadius: "20px !important", padding: "10px 11px !important" }}>{item.title[language]}</Accordion.Header>
                                                                        <Accordion.Body className="p-0">
                                                                            {item?.content?.map((value, index) => {
                                                                                return (
                                                                                    <div className="accordion-body accordion_bodycolor my-2" key={value?.id}>
                                                                                        <div className="row align-items-center">
                                                                                            {(index % 2) == 0 ?
                                                                                                <>  <div className="col-3 col-xl-1">
                                                                                                    <img src={value?.file_path[language]} alt="" width={80} style={{ marginLeft: -15 }} />
                                                                                                </div>
                                                                                                    <div className="col-9 col-xl-11">
                                                                                                        <div className="text_ab">
                                                                                                            <p className="mb-0" >
                                                                                                                {/* <strong style={{ fontSize: 16 }}>{item.heading[language]}</strong> */}

                                                                                                                <span style={{ fontSize: 16, fontWeight: 300 }}>{parse((value.short_description[language]).replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""))}</span>
                                                                                                                {/* <span style={{ fontSize: 16, fontWeight: 300 }}>{parse(value.short_description[language])}</span> */}

                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>

                                                                                                :
                                                                                                <>

                                                                                                    <div className="col-9 col-xl-11">
                                                                                                        <div className="text_ab">
                                                                                                            <p className="mb-0">
                                                                                                                {/* <strong style={{ fontSize: 16 }}>{item.heading[language]}</strong> */}
                                                                                                                <span style={{ fontSize: 16, fontWeight: 300 }}> {parse((value.short_description[language]).replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""))}</span>
                                                                                                                {/* <span style={{ fontSize: 16, fontWeight: 300 }}> {parse(value.short_description[language]).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "")}</span> */}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-3  col-xl-1">
                                                                                                        <img src={value?.file_path[language]} alt="" width={80} />
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                            })}

                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>

                                                        }
                                                    </div>

                                                )
                                            })}


                                        </div>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="acc-btn accordian-btn" style={{ borderRadius: "20px !important", padding: "10px 11px !important" }}>{language === "english" ? "Digital Ambassdor list" : "डिजिटल राजदूत सूची"}</Accordion.Header>
                                                <Accordion.Body>
                                                    <table className="table table-striped custom-table">
                                                        <thead>
                                                            <tr className="custom-head">

                                                                <th scope="col">Name</th>

                                                                <th scope="col">Class</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.ambassdor_summary?.map((item, index) => {
                                                                return (
                                                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                                        <td >{item.title[language]}</td>
                                                                        <td>{item.class_name}</td>
                                                                    </tr>
                                                                )

                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>


                                    </section>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default AboutUs