import React, { useContext, useEffect, useState, useMemo } from "react";
import Header from "../components/Header"
import LanguageContext from "../components/hooks/LanguageContext";
import axios from "axios";
import LanguageSet from "../components/language/LanguageSet";
import LoadingSnipQuiz from "../components/LoadingSnipQuiz";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";


const API_ENDLINE = `${Constants.BASE_URL}/endline`;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const SingleSelect = React.memo(({ data, language, handleOptionChange, optionMarked, selectedOptions }) => {

    return (
        <div className="textBox pb-1 pt-2 position-relative">
            <div className="shareEmail3 ">

                <div><p>{data.title[language]}</p></div>
                <form className="single-type pt-2">
                    <div className="row justify-content-center text-center">
                        {data?.options?.map((option, index) => (
                            <label key={index}>
                                <input
                                    type="radio"
                                    name="option"
                                    value={option}
                                    checked={
                                        (optionMarked && Number(optionMarked.id) === Number(option.id)) ||
                                        (selectedOptions.values && Number(selectedOptions.values.id) === Number(option.id))
                                    }
                                    onChange={() => handleOptionChange("single_select", data.id, option)}
                                />
                                <span style={{ textAlign: "start" }}>{option.title[language]} </span>

                            </label>
                        ))
                        }
                    </div>
                </form>
            </div>
        </div>
    )
});

const SingleSelectInput = React.memo(({ data, language, handleOptionChange, selectedOptions }) => {
    console.warn(selectedOptions)
    return (
        <div className="textBox pb-1 pt-2 position-relative">
            <div className="shareEmail3 ">

                <div><p>{data.title[language]}</p></div>
                <form className="single-type pt-2">
                    <div className="row justify-content-center text-center">
                        <textarea
                            className="form-control"
                            placeholder={language === "english" ? "Write here:" : "यहाँ लिखें:"}
                            id="floatingTextarea"
                            value={selectedOptions.values}
                            onChange={(event) => { handleOptionChange('input', data.id, event.target.value) }}
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
    )
});

const InputRadio = React.memo(({ data, language, handleOptionChange, selectedOptions, optionMarked, textValue, handleTextChange, error }) => {

    let isOption7Selected;
    if (optionMarked && optionMarked.id) {
        isOption7Selected = optionMarked.id === selectedOptions?.input_option_id;
    } else {
        isOption7Selected = selectedOptions?.values?.id === selectedOptions?.input_option_id;
    }


    
    return (
        <div className="textBox pb-1 pt-2 position-relative">
            <div className="shareEmail3 ">

                <div><p>{data.title[language]}</p></div>
                <form className="single-type pt-2">
                    <div className="row justify-content-center text-center">
                        {data?.options?.map((option, index) => (
                            <label key={index}>
                                <input
                                    type="radio"
                                    name="option"
                                    value={option}
                                    checked={
                                        (optionMarked && Number(optionMarked.id) === Number(option.id))
                                        ||
                                        (selectedOptions.values && Number(selectedOptions.values.id) === Number(option.id))
                                    }
                                    onChange={() => handleOptionChange("input_radio", data.id, option)}
                                />
                                <span style={{ textAlign: "start" }}>{option.title[language]} </span>

                            </label>
                        ))}
                        {isOption7Selected && (
                            <>
                                <input
                                    required
                                    type="text"
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    value={textValue}
                                    onChange={(event) => { handleTextChange(event.target.value) }}
                                />
                                {error && <div className="invalid-feedback" style={{ textAlign: "start" }}>{error}</div>}
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
})

const EndLine = () => {
    const { user, logout: authLogout } = useAuth(); // Use useAuth
    const { language } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [showDI, setShowDI] = useState(true)
    const [showQuestions, setShowQuestions] = useState(false)
    const [questions, setQuestions] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({
        question_type: "",
        question_id: "",
        values: "",
        input_option_id: "",

    });
    const [textValue, setTextValue] = useState("")
    const [nextBtnEnable, setNextBtnEnable] = useState(true)
    const [questionId, setQuestionId] = useState("")
    const [optionMarked, setOptionMarked] = useState({})
    const navigate = useNavigate();
    const [checkProgress, setCheckProgress] = useState(true);
    const [error, setError] = useState("");
    const [showThankYou, setShowThankYou] = useState(false)
    // Memoize the data object
    const memoizedData = useMemo(() => data, [data]);

    useEffect(() => {
        const fetchData = async () => {
            
            setLoading(true); // Set loading to true
            try {
                const response = await axios.get(`${API_ENDLINE}?access_token=${user?.token}`);
                

                if (response.status === 200) {
                    
                    setData(response.data.endline_summary)
                } else {
                    console.error(' false');
                }
            } catch (error) {
                console.error(' failed:', error);
            } finally {
                setLoading(false); // Set loading to false after the API call is completed
            }
        };

        const fetchQuesData = async () => {
            setLoading(true); // Set loading to true
            try {

                // Or example of parallel requests using Promise.all
                const [questionResponse, questionAnsResponse] = await Promise.all([
                    axios.get(`${memoizedData.url}&access_token=${user?.token}&page=${currentPage}`, {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            "Access-Control-Allow-Origin": "*"
                        }
                    }),
                    axios.get(`${Constants.BASE_URL}/endline-response?access_token=${user?.token}&page=${currentPage}&endline_id=1`, {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                ]);

              

                if (questionResponse?.status === 200 && questionAnsResponse?.status === 200) {
                    // Handle the response data if needed
                    setTotalCount(questionResponse?.data?.summary?.total_page)
                    setCurrentPage(questionResponse?.data?.summary?.current_page)
                    setQuestionId(questionResponse?.data?.endline_question[0]?.id)
                    setQuestions(questionResponse?.data)

                    const questionType = questionResponse.data.endline_question[0].question_type;

                    // Update selectedOptions based on the question type
                    setSelectedOptions(prevOptions => {
                        if (questionType === "input_radio") {
                            
                            return {
                                ...prevOptions,
                                question_type: questionType,
                                question_id: questionResponse.data.endline_question[0].id,
                                input_option_id: questionResponse.data.endline_question[0].input_option_id
                            };
                        } else if (questionType === "single_select") {
                            return {
                                ...prevOptions,
                                question_type: questionType,
                                question_id: questionAnsResponse.data.endline_question[0].id,
                                values: questionAnsResponse.data.endline_question[0].option_marked
                            };
                        } else if (questionType === "input") {
                            return {
                                ...prevOptions,
                                question_type: questionType,
                                question_id: questionAnsResponse.data.endline_question[0].id,
                                values: questionAnsResponse.data.endline_question[0].option_marked.title,
                            };
                        }
                    });
                    setOptionMarked(questionAnsResponse.data.endline_question[0].option_marked)
                    if (questionAnsResponse.data.endline_question[0].is_marked === 1) {
                        setNextBtnEnable(true)
                    }
                }

                
            } catch (error) {
                console.error('Error fetching user from localStorage:', error);
            } finally {
                setLoading(false); // Set loading to false after the API call is completed
            }
        };

        const fetchProgressData = async () => {
            // Logic for the third API call
            try {
                setLoading(false)
                
                const response2 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`);
                
                if (response2.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    const courseProgress = response2.data.student_summary.course_progress;

                    // Check if all progress_percentage values are greater than or equal to 80
                    const shouldContinue = courseProgress.every((course) => course.progress_percentage >= 80);

                    if (shouldContinue) {
                        setCheckProgress(true)
                    } else {
                        // Go back
                        navigate(-1);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                // Set loading to false when the API call is completed
                setLoading(false);
            }
        };

        if (!data) {
            fetchData();
        } else if (showQuestions) {
            fetchQuesData();
        } else {
            // fetchProgressData();
        }
    }, [data, showQuestions, user?.token, memoizedData, currentPage, navigate]);


    const renderQuestion = (question) => {
        
        switch (question.question_type) {
            case "single_select":
                return <SingleSelect data={question} language={language} handleOptionChange={handleOptionChange} optionMarked={optionMarked} selectedOptions={selectedOptions} />
            case "input":
                return <SingleSelectInput data={question} language={language} handleOptionChange={handleOptionChange} selectedOptions={selectedOptions} />
            case "input_radio":
                return <InputRadio data={question} language={language} handleOptionChange={handleOptionChange} optionMarked={optionMarked} selectedOptions={selectedOptions} textValue={textValue} handleTextChange={handleTextChange} error={error} />
            default: return <div>default</div>
        }
    }

    const checkPreviousQues = async (submitUrl) => {

        const response = await axios.post(submitUrl);

        return response

    }

    const handlePreviousClick = async () => {
        if (Number(currentPage) === 26 || Number(currentPage) === 28) {
            let setPage = 0
            if (Number(currentPage) === 26) {
                setPage = 24
            } else {
                setPage = 26
            }
            let submitUrl = `${Constants.BASE_URL}/endline-response?access_token=${user?.token}&page=${setPage}&endline_id=1`;
            try {
                const response = await checkPreviousQues(submitUrl);
                const response_data = response.data.endline_question[0];
                
                let question_id = response_data.id;
                let option_marked_id = response_data.option_marked.id;
                
                if ((question_id === 30 && option_marked_id === 139) || (question_id === 32 && option_marked_id === 141)) {
                    setCurrentPage(Number(currentPage) - 2);
                } else {
                    setCurrentPage(Number(currentPage) - 1);
                }
            } catch (error) {
                console.error("Error:", error);
                // Handle errors if necessary
            }

        } else if (Number(currentPage) !== 1) {
            setCurrentPage(Number(currentPage) - 1)
        } else if (Number(currentPage) === 1) {
            setCurrentPage(1)
            navigate("/")
        } else {
            setCurrentPage(1)
        }
    }

    const handleNextClick = async () => {

        setNextBtnEnable(false)
        if (Number(currentPage) < Number(totalCount)) {
            // console.warn(selectedOptions)
            let submitUrl = ""
            if (selectedOptions.question_type === "single_select") {
                // For single select, use the "option_id" property in the API request
                submitUrl = `${Constants.BASE_URL}/endline-markasdone?endline_id=1&endline_question_id=${questionId}&option_id=${selectedOptions.values.id}&access_token=${user?.token}`;
            } else if (selectedOptions.question_type === "input") {
                // For textarea, use the "answer" property in the API request
                submitUrl = `${Constants.BASE_URL}/endline-markasdone?endline_id=1&endline_question_id=${questionId}&answer=${selectedOptions.values}&access_token=${user?.token}`;
            } else if (selectedOptions.question_type === "input_radio") {
                if (textValue.length > 0) {
                    submitUrl = `${Constants.BASE_URL}/endline-markasdone?endline_id=1&endline_question_id=${questionId}&option_id=${selectedOptions.values.id}&answer=${textValue}&access_token=${user?.token}`;
                } else if (textValue.length === 0 && selectedOptions.values.id === selectedOptions.input_option_id) {
                    toast.warn("Please mark your answer.");
                    setError("Please mark your answer.")
                    setNextBtnEnable(true);  // Enable the Next button again
                    return;  // Do not proceed further
                } else {
                    submitUrl = `${Constants.BASE_URL}/endline-markasdone?endline_id=1&endline_question_id=${questionId}&option_id=${selectedOptions.values.id}&access_token=${user?.token}`;
                }

            }
            

            if (Object.keys(selectedOptions).length > 0) {

                try {
                    setLoading(true);
                    
                    const response = await axios.post(submitUrl);
                    
                    if (response.data.message === "Invalid Access Token") {
                        authLogout()
                        navigate("/");
                    } else {
                        let question_id = response.data.endline_result[0].endline_question_id
                        let option_marked_id = response.data.endline_result[0].option_marked.id
                        
                        if ((question_id === 30 && option_marked_id === 139) || (question_id === 32 && option_marked_id === 141)) {
                            setCurrentPage(Number(currentPage) + 2);
                        } else {
                            setCurrentPage(Number(currentPage) + 1);
                        }

                        setSelectedOptions({
                            question_type: "",
                            question_id: "",
                            values: ""
                        })
                    }


                } catch (error) {
                    console.error(error);
                    setSelectedOptions({
                        question_type: "",
                        question_id: "",
                        values: ""
                    })

                } finally {
                    // Set loading to false when the request is complete
                    setLoading(false);
                }
            } else {
                // setCurrentPage(Number(currentPage) + 1)
                // setSelectedOptions({})
                toast.warn("Please choose an option.");
            }



        }
    }

    const finalSubmitReq = async () => {
        


        try {
            setLoading(true);
            if (Object.keys(selectedOptions).length > 0) {
                const submitUrl = `${Constants.BASE_URL}/endline-markasdone?endline_id=1&endline_question_id=${questionId}&option_id=${selectedOptions.values.id}&access_token=${user?.token}`;
                const response = await axios.post(submitUrl);
                
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setSelectedOptions({})
                }
            }

            // Call the second API using data from the first API
            const response2 = await fetch(`${Constants.BASE_URL}/endline-final-submit?access_token=${user?.token}&endline_id=1`);
            const data2 = await response2.json();
            
            if (data2?.summary?.final_submit_status === 1) {
                // navigate("/")
                setShowQuestions(false)
                setShowThankYou(true)
            } else {
                toast.error(data2?.summary?.message);
                // setBaseLineQuizCompleted(true); setAgeSubmitted(true)
                // navigate("/")
            }
        } catch (error) {
            console.error(error);
            setSelectedOptions({})
        } finally {
            // Set loading to false when the request is complete
            setLoading(false);
        }

    }


    const handleOptionChange = (question_type, question_id, values) => {
        setOptionMarked({})
        
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            question_type: question_type,
            question_id: question_id,
            values: values,
        }))
        setNextBtnEnable(true)

    };

    const handleTextChange = (value) => {
        setError("")
        
        setTextValue(value)
    }

    return (
        <div id="body_bg">
            <Header
                page_title={(user?.student_name[language] === null ? "" : user?.student_name[language]) + " (" + user?.student_id + ")"}
            />
            <LanguageSet />
            {loading ? (<LoadingSnipQuiz />)
                :
                <>
                    {checkProgress && showDI && (
                        <div className="container-fluid px-0">
                            <div className="row justify-content-center">
                                <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="sectionsBox">
                                                        <p className="mb-0 text-center">{data && data?.title[language]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="contentbox mb-4 mt-3">
                                                        {data && data?.description[language] && <div className="content_safty ">
                                                            {parse(data?.description[language])}
                                                        </div>
                                                        }
                                                        {data && data?.instruction[language].length > 0 && <div className="content_safty_ol_li ">
                                                            {parse(data?.instruction[language])}

                                                        </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row justify-content-md-center">
                                                <div className="col col-lg-2">

                                                </div>
                                                <div className="col-md-auto justify-content-center d-flex">
                                                    <button onClick={() => { setShowQuestions(true); setShowDI(false) }} type="button" className="btn btn-danger  custom-button-next">
                                                        {language === "english" ? "Next" : "अगला"}
                                                    </button>
                                                </div>
                                                <div className="col col-lg-2">

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>

                        </div>
                    )}
                    {showQuestions &&
                        (<div className="container-fluid px-0 background-image">
                            <div className="row justify-content-center">
                                <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid">
                                            <>
                                                {questions && renderQuestion(questions?.endline_question[0])}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mark-buttons d-flex justify-content-between " style={{ marginTop: 150, marginBottom: 100 }}>
                                                            <div className="left-btn"> <button onClick={handlePreviousClick}
                                                                disabled={Number(currentPage) === 1}
                                                                className="previous_btn_baseline"
                                                            >
                                                                <FontAwesomeIcon icon={faArrowLeft} size="lg" color="#fff" />
                                                            </button>

                                                            </div>

                                                            {Number(currentPage) === Number(totalCount) ? <div className="markButtons"> <button
                                                                className="d-flex align-items-center gap-2"
                                                                onClick={() => { finalSubmitReq() }}
                                                                style={{ backgroundColor: nextBtnEnable === false ? "#c8c8c8" : Object.keys(selectedOptions).length === 0 ? "#c8c8c8" : "#792110", color: nextBtnEnable === false ? "#212529" : Object.keys(selectedOptions).length === 0 ? "#212529" : "#ffffff" }}
                                                                disabled={nextBtnEnable === false ? true : Object.keys(selectedOptions).length === 0 ? true : false}
                                                            >
                                                                {language === "english" ? "Submit" : "जमा करें"}
                                                                {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}

                                                            </button>
                                                            </div>
                                                                : ""}

                                                            <div className="right-btn">
                                                                <button className="previous_btn_baseline"
                                                                    onClick={handleNextClick}
                                                                    disabled={
                                                                        Number(currentPage) === Number(totalCount) ||
                                                                        nextBtnEnable === false ||
                                                                        !selectedOptions ||  // Check if selectedOptions is undefined or null
                                                                        Object.keys(selectedOptions).length === 0
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            Number(currentPage) === Number(totalCount) ? "#c8c8c8" : nextBtnEnable === false ? "#c8c8c8" : !selectedOptions ? "#c8c8c8" : Object.keys(selectedOptions).length === 0 ? "#c8c8c8" : "#792110",
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faArrowRight} size="lg" color="#fff" />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        )
                    }
                    {showThankYou &&

                        <div className="container-fluid px-0 background-image">
                            <div className="row justify-content-center">
                                <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid">
                                            <div className="col-12">

                                                <div className="thanksmsg my-4">
                                                    {language === "english" && <div onClick={() => { navigate("/") }}><img src={require("../assets/images/section4/thankyou1.png")} alt="" width="100%" /></div>}
                                                    {language === "hindi" && <div onClick={() => { navigate("/") }}><img src={require("../assets/images/section4/thankyou2.png")} alt="" width="100%" /></div>}
                                                </div>
                                            </div>

                                            <div className="markButtons d-flex align-items-center justify-content-center"> <button
                                                className=" gap-2"
                                                onClick={() => { navigate("/") }}
                                                style={{ backgroundColor: "#792110", color: "#ffffff" }}
                                            >
                                                {language === "english" ? "Next" : "अगला"}

                                            </button>
                                            </div>

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    }


                </>
            }
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default EndLine