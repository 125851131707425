import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import homeData from "../api/home.json";
import LanguageContext from "../components/hooks/LanguageContext";
import LanguageSet from "../components/language/LanguageSet";
import axios from "axios";
import LoadingSnip from "../components/LoadingSnip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ProgressBarPrec from "../components/ProgressBarPrec";
import Constants from "../common/Constants";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../providers/AuthContext";


const API_COURSE_LIST = `${Constants.BASE_URL}/course-list`;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;
const API_ANNOUNCMENT = `${Constants.BASE_URL}/appannouncement`;
const API_TESTIMONIALS = `${Constants.BASE_URL}/apptestimonial`;

function HomePage() {
  const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const [sections, setSections] = useState(null);
  const [module, setModule] = useState(null)
  const [announcment, setAnnouncement] = useState(null)
  const [testimonials, setTestimonials] = useState(null)

  const navigate = useNavigate();


  useEffect(() => {

    const fetchData = async () => {
      try {
        setLoading(true);

        const [courseListResponse, studentProgressResponse, announcementResponse, testimonialTesponse] = await Promise.all([
          axios.get(`${API_COURSE_LIST}`),
          axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`, {
            headers: {
              'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
              "Access-Control-Allow-Origin": "*"
            },
          }),
          axios.post(`${API_ANNOUNCMENT}?access_token=${user?.token}`, {
            headers: {
              'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
              "Access-Control-Allow-Origin": "*"
            },
          }),
          axios.post(`${API_TESTIMONIALS}?access_token=${user?.token}`, {
            headers: {
              'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
              "Access-Control-Allow-Origin": "*"
            },
          })
        ]);

        if (courseListResponse.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else if (courseListResponse.status === 200) {
          
          setSections(courseListResponse.data);
        } else {
          // Handle other specific errors based on the response status or message
          console.error('Error in API response:', courseListResponse.data.message || 'Unknown error');
          toast.error('An error occurred while fetching data. Please try again.');
        }

        if (studentProgressResponse.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else if (studentProgressResponse.status === 200) {
          setModule(studentProgressResponse.data)
          setMyPoints(studentProgressResponse.data.student_summary.points)
        } else {
          // Handle other specific errors based on the response status or message
          console.error('Error in API response:', studentProgressResponse.data.message || 'Unknown error');
          toast.error('An error occurred while fetching data. Please try again.');
        }

        if (announcementResponse.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else if (announcementResponse.status === 200) {
          setAnnouncement(announcementResponse.data)
        } else {
          // Handle other specific errors based on the response status or message
          console.error('Error in API response:', announcementResponse.data.message || 'Unknown error');
          toast.error('An error occurred while fetching data. Please try again.');
        }         


        if (testimonialTesponse.data.message === "Invalid Access Token") {
          authLogout()
          navigate("/");
        } else if (testimonialTesponse.status === 200) {
          setTestimonials(testimonialTesponse.data)
        } else {
          // Handle other specific errors based on the response status or message
          console.error('Error in API response:', announcementResponse.data.message || 'Unknown error');
          toast.error('An error occurred while fetching data. Please try again.');
        }
      } catch (error) {
        // console.error(error);
        setLoading(false)
        toast.error('An error occurred while fetching data. Please try again.');
      } finally {
        setLoading(false)
      }
    }
    fetchData();
  }, [user?.token]);


  const sectionNumber = (title) => {
    switch (title) {
      case "Digital Safety":
        return 1;
      case "Digital Well-Being":
        return 2;
      case "Digital Information Ecosystem":
        return 3;
      case "Digital Agency":
        return 4;
      default:
        return "";
    }
  };

  return (
    <div id="body_bg">
      <Header
        page_title={(user?.student_name[language] === null ? "" : user?.student_name[language]) + " (" + user?.student_id + ")"}
      />
      <LanguageSet />
      {loading ? <LoadingSnip />
        :

        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="container-fluid px-0">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-md-12">
                <div className="container-fluid ">
                  <div className="row">
                    <div className="col-12 text-center">
                      <img src={homeData.logo[language]} alt="logo" width="215" />
                    </div>
                  </div>
                </div>
                <div className="container-fluid my-4">
                  <div className="box-saftyBg">
                    <div className="row">
                      {sections?.course_summary?.map((item, index) => {
                        return (
                          <div
                            className="col-6 text-center pt-3 pb-4"
                            key={index}
                          >
                            <div className="safty-content">
                              <div
                                onClick={() => {
                                  navigate(homeData.sections[index].goto, {
                                    state: {
                                      url: item.url,
                                      page_title: item.title,
                                    },
                                  });
                                }}
                              >
                                <img src={item.image_path[language]} alt="item-title" />
                                <p className="mb-0">{item.title[language]}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="container-fluid mt-4">
                  <div className="progressBox">
                    <div className="row ">
                      <div className="col-3 col-xxl-2 col-xl-3 col-lg-1">
                        <div className="imogi">
                          <img
                            src={require("../assets/images/imogi1.png")}
                            alt="my-progress"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-xxl-10 col-xl-9 col-lg-11">
                        <div className="task-progress mb-3">
                          <p>
                            {language === "english"
                              ? "My Progress"
                              : "मेरी प्रगति"}
                          </p>
                          <div className="progresssteps d-flex gap-1">
                            <ProgressBarPrec progress={Number(module?.student_summary?.my_progress)} />
                          </div>
                        </div>
                        <div className="task-progress mb-3">
                          <p></p>
                          <p>
                            {language === "english"
                              ? `Section ${sectionNumber(module?.student_summary?.section_active_progress?.course_title["english"])} (${module?.student_summary?.section_active_progress?.course_title[language]}) Progress`
                              : `अनुभाग - ${sectionNumber(module?.student_summary?.section_active_progress?.course_title["english"])} (${module?.student_summary?.section_active_progress?.course_title[language]}) प्रगति`}
                          </p>
                          <div className="progresssteps d-flex gap-1">
                            <ProgressBarPrec progress={Number(module?.student_summary?.section_active_progress?.progress_percentage)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 pb-2">
                        <div className="start-pro mt-3 d-flex justify-content-between align-items-center">
                          <div className="p">
                            <p
                              className="mb-0"
                              style={{ fontSize: 14, fontWeight: 400 }}
                            >
                              {homeData.my_score_title[language]}
                            </p>
                          </div>
                          <div className="startRating d-flex gap-2 align-items-center">
                            <i className="fa-solid fa-solid fa-star"></i>
                            <h4 className="mb-0">
                              {" "}
                              <FontAwesomeIcon
                                icon={faStar}
                                size="sm"
                                color="#f3db7b"
                                className="me-2"
                              />
                              {module && Number(module.student_summary.points)}/ {sections?.total_course_points}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-4 mb-4">
                  <div className="col-12 pb-3">
                    <div className="sectionsBox">
                      <p className="mb-0 text-center">
                        {language === "english" ? "Announcements" : "घोषणाएं"}
                      </p>
                    </div>
                  </div>
                  <Carousel
                    dynamicHeight={false}
                    emulateTouch={true}
                    infiniteLoop={true}
                    showArrows={false}
                    swipeable={true}
                    showThumbs={false}
                    autoPlay={true}
                  >
                    {announcment?.announcement_summary?.map((item, index) => {
                      
                      return (
                        <div
                          key={index}
                          className="carousel-item active h-100"
                          data-bs-interval="10000"
                        >
                          <div
                            className="card h-100"
                            // style={{ backgroundColor: "#f7e69d" }}
                            style={{ border: "none", padding: 0 }}
                          >
                            <div className="card-body" >
                              <div className="banner_ann">
                                <img
                                  src={item.file_path[language]}
                                  className="card-img-top"
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                <div className="container-fluid mt-5">
                  <div className="col-12 pb-3">
                    <div className="sectionsBox">
                      <p className="mb-0 text-center">
                        {language === "english" ? "Testimonials" : "प्रशंसापत्र"}
                      </p>
                    </div>
                  </div>
                  <Carousel
                    dynamicHeight={false}
                    // emulateTouch={true}
                    infiniteLoop={true}
                    showArrows={false}
                    swipeable={true}
                    showThumbs={false}
                  // autoPlay={true}
                  >
                    {testimonials?.testimonial_summary?.map((item, index) => {
                      
                      return (
                        <div
                          key={index}
                          className="carousel-item active h-100"
                          data-bs-interval="10000"
                        >
                          <div
                            className="card h-100"
                            style={{ backgroundColor: "#f7e69d" }}
                          >
                            <div className="card-body" >
                              <div className="test-title">
                                <p>{item.title[language]}</p>
                              </div>
                              <div className="testimonials-content">
                                <p className="card-text">
                                  <img src={require("../assets/images/commas1.png")} alt={`"`} className="comma-image left-comma" />
                                  {item.description[language]}
                                  <img src={require("../assets/images/commas2.png")} alt={`"`} className="comma-image right-comma" />
                                </p>
                              </div>
                              <div className="authorName">
                                <p>{item.name[language]}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-7 col-md-12">
                  <div
                    className="container-fluid my-4"
                    style={{ paddingTop: 50 }}
                  >
                    <div className="d-flex justify-content-center">
                      <Link
                        className="mr-2"
                        to="https://theylacproject.com/ "
                        target="_blank"
                      >
                        <img style={{ widt: 28, height: 28, marginTop: -1 }} src={require("../assets/images/social/ylac.png")} alt="ylac" />
                      </Link>
                      <Link
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                        to="https://www.facebook.com/ylacxofficial"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="xl"
                          color="#792110"
                        />
                      </Link>
                      <Link
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                        to="https://twitter.com/ylacofficial?t=iaPLhH6jnKSuvGkcKfU_jQ&s=08"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          size="xl"
                          color="#792110"
                        />
                      </Link>
                      <Link
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                        to="https://www.instagram.com/ylacofficial/"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="xl"
                          color="#792110"
                        />
                      </Link>
                      <Link
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                        to="https://www.youtube.com/@youngleadersforactivecitiz1386 "
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faYoutube}
                          size="xl"
                          color="#792110"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default HomePage;
