// src/components/Ball.js
import React from 'react';
import { useDrag } from 'react-dnd';

const BallMatch = ({  quisAnswer, id, text, ballStatus}) => {
  
  const [{ isDragging }, drag] = useDrag({
    type: id,
    item: { quisAnswer },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleImageTouchStart = (e) => {
    e.preventDefault();
  };

  return (
    <>
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginLeft:-20
      }}
      className='container ball-size'
    >

{/* {ballStatus === false ? <img src={require("../../assets/images/dragdrop/match/qus.png")} /> : <img src={require("../../assets/images/dragdrop/match/qus.png")} style={{filter:'blur(2px)'}}/>}
      <div className="" style={{fontSize:14, color: ballStatus === true ? "green" : "#000"  }}>{text}</div>   */}
       {ballStatus === false ? <img  src={require("../../assets/images/dragdrop/gameball.png")} onTouchStart={handleImageTouchStart}/> :  <img src={require("../../assets/images/dragdrop/gameball.png")} style={{filter:'blur(2px)'}} onTouchStart={handleImageTouchStart}/>}
      <div className="centered .no-select" style={{fontSize:11, color: ballStatus === true ? "#F3DB73" : "#fff"  }}>{text}</div>
    </div>
    
    </>
  );
};

export default BallMatch;
